<template>
	<div class="Troop">
		
		<div class="Top">
			<div class="Left">
				<template v-if="Setting != null && Setting.Key != 'add'">配置详情</template>
				<template v-if="Setting != null && Setting.Key == 'add'">添加配置</template>
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				<li>
					<span>配置名称</span>
					<em v-if="Setting != null">
						<el-input v-model="Setting.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				
				<li>
					<span>配置键(Key)</span>
					<em v-if="Setting != null && $route.params.Key != 'add'">
						{{Setting.Key}}
					</em>
					<em v-else>
						<el-input v-model="Setting.Key" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>配置值(Val)</span>
					<em>
						<el-input type="textarea" v-model="Setting.Val" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>描述</span>
					<em  v-if="Setting != null">
						<el-input v-model="Setting.Description" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>配置状态</span>
					<em  v-if="Setting != null">
						<el-select placeholder="设置账号状态" v-model="Setting.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="Setting != null && $route.params.Key != 'add'">
					<span>创建时间</span>
					<em>
						{{Setting.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Setting != null && $route.params.Key != 'add'">
					<span>最后修改时间</span>
					<em>
						{{Setting.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button @click="Update()" type="warning" v-if="Setting != null && $route.params.Key == 'add'">添加配置</el-button>
						<el-button @click="Update()" type="warning" v-else>修改配置</el-button>
					</em>
				</li>
			</div>
		</div>

	</div>
</template>

<script>
	import {Select,Option} from 'element-ui'
	export default {
	  name: 'SettingInfo',
	  props: {
	  },
	  data() {
	      return {
			  Setting:null,
			  IsPosting:false
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
	  },
	  created() {
	  	if(this.$route.params.Key == undefined){
			this.$message('缺少配置key，页面无法工作')
			return
		}
		if(this.$route.params.Key == 'add'){
			this.InitSetting()
			return
		}
		this.GetSetting(this.$route.params.Key)
	  },
		methods:{
			InitSetting(){
				this.Setting = {
					Key:'',
					Name:'',
					Val:'',
					Description:'',
					Status:50
				}
			},
		    GetSetting(_key){
		  		let that = this
		  		let data = {Service:'System',Class: 'Setting',Action: 'Get',Key:_key}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.Setting = res.Data
		  			
		  		})
		    },
			Update(){
				let that = this
				
				if(that.Setting.Key.length < 2){
					that.$message('键必须填入')
					return
				}
				
				if(that.IsPosting){
					return
				}
				that.IsPosting = true
				
				let data = {Service:'System',Class: 'Setting',Action: 'Update',Key:that.Setting.Key,Status:that.Setting.Status,Name:that.Setting.Name,Val:that.Setting.Val,Description:that.Setting.Description}
				if(that.$route.params.Key == 'add'){
					data.Action = 'Add'
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.IsPosting = false
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						if(that.$route.params.Key != 'add'){
							that.$Jump('/do/setting/system')
						}else{
							that.$Jump('/do/setting/system')
						}
					}
				})
				.catch(function (response) {
					that.IsPosting = false
				})
			},
		
		}
	}
</script>

<style scoped>
.Troop{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Troop .Top{
	display: flex;
	align-items: center;
}
.Troop .Top .Left{
	
}
.Troop .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Troop .List{
	margin-top: 20px;	
}
.Troop .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Troop .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Troop .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Troop .List .One li  em{
	flex: 1;
}
.Troop .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

</style>
